<template>
  <div class="career-banner content-container-template">
    <div class="career-banner__wrapper no-shrink">
      <div class="career-banner__content">
        <UiImg
          :image="certImage"
          class="career-banner__accent-image"
          use-image-size
        />

        <T3HtmlParser
          v-if="bodytext"
          :content="bodytext"
          class="career-banner__text"
        />
      </div>
    </div>

    <div class="career-banner__photo-wrapper row v-end grow">
      <UiImg
        :image="personImage"
        class="career-banner__image"
        use-image-size
      />

      <img
        v-if="image"
        class="career-banner__icon"
        src="@/assets/logo-maco.svg?url"
        alt=""
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { UiImg } from '~ui/components'
import type { UiCeCaseStudyBannerProps } from '~ui/types'

const props = defineProps<UiCeCaseStudyBannerProps>()

const certImage = computed(() => props.logo?.[0] || '')
const personImage = computed(() => props.image?.[0] || '')
</script>

<style lang="scss">
.career-banner {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-flow: column;

  @include media-query(sm) {
    flex-direction: row;
  }

  &__wrapper {
    padding: spacing(xl) spacing(md) spacing(xl) 0;
    width: 52.5%;

    @include media-query(max sm) {
      padding: rem(30px) 0 0;
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  &__accent-image {
    margin-bottom: rem(32px);
    margin-right: auto;
  }

  &__text {
    position: relative;
    z-index: z-index(over);
    margin-block: auto;
  }

  &__accent-image + &__text {
    margin-block: 0 auto;
  }

  &__photo-wrapper {
    position: relative;
    margin-top: rem(24px);
    max-width: 42.5%;

    @include media-query(max sm) {
      max-width: 100%;
    }
  }

  &__image {
    position: relative;
    display: block;
    width: auto;
    height: auto;
    translate: rem(-30px) 0;
    max-width: 105%;
    z-index: z-index(base);

    @include media-query(md) {
      translate: rem(-50px) 0;
    }

    @include media-query(max sm) {
      max-width: 100%;
      max-height: rem(360px);
      margin-inline: auto;
      translate: 0 0;
    }
  }

  &__icon {
    width: clamp(rem(200px), 23vw, rem(343px));
    position: absolute;
    bottom: 0;
    right: 0;
    height: auto;
    z-index: z-index(neutral);
  }
}
</style>
